import React, { useEffect, useRef, useState } from 'react';
import { useRacingState } from '@apollo/core/src/state/racing/racing';
import { Bus } from '@apollo/core';
import { selectNextToJump } from '../../core/utils';

const NEXT_RACES_UPDATE_MIN_INTERVAL_MS = 1000;

const useNextRaces = ({ raceTypes, limit = 50, updateOnMount = true }) => {
  const [racingState] = useRacingState();
  const { races, lastRacesUpdate } = racingState;
  const [nextToJump, setNextToJump] = useState([]);
  const nextRaceRef = useRef();

  const nextRacesUpdate = () => {
    Bus.emit(Bus.events.racing.loadNextRaces);
  };

  const onNextJumpStart = React.useCallback(() => {
    const list = selectNextToJump({
      races,
      limit,
      types: raceTypes,
    });
    if (list[0] && nextRaceRef.current?.raceId !== list[0].raceId) {
      // update list if the first race gone
      if (!lastRacesUpdate || new Date() - lastRacesUpdate > NEXT_RACES_UPDATE_MIN_INTERVAL_MS) {
        nextRacesUpdate();
      }
      nextRaceRef.current = list[0];
    }
    setNextToJump(list);
  }, [limit, races, lastRacesUpdate, raceTypes]);

  useEffect(() => {
    if (races.length && updateOnMount) {
      // update list of races on mount
      nextRacesUpdate();
    }
  }, [updateOnMount]);

  useEffect(() => {
    onNextJumpStart();
  }, [onNextJumpStart]);

  return [nextToJump, nextRacesUpdate];
};
export default useNextRaces;
