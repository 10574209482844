import React, { useCallback, useMemo } from 'react';

import { Bus, useApplicationState, useOutsideClick } from '@apollo/core';

function getDecimalCount(x) {
  if (x?.toString().includes('.')) {
    return x.toString().split('.')[1].length;
  }
  return 0;
}

/**
 inputParentSelector — check if we have input in element with ParentSelector
 fixed — keypad with fixed position
 */
const KeyPad = ({ inputParentSelector, fixed }) => {
  const ref = React.useRef();

  const { layout } = useApplicationState();

  const hasSelectedParent = useMemo(
    () => layout.keyPadMoneyInput?.closest(inputParentSelector),
    [layout.keyPadMoneyInput, inputParentSelector],
  );

  const hideKeyPad = useCallback(() => {
    // applay changes
    layout.keyPadMoneyInput?.setFakeInputText(layout.keyPadMoneyInput?.value);
    // hide KeyPad
    Bus.send({
      event: Bus.events.layout.setLayout,
      data: {
        ...layout,
        keyPadMoneyInput: undefined,
      },
    });
  }, [layout]);

  const onBtnClick = useCallback(
    (value) => {
      if (layout.keyPadMoneyInput?.readOnly) {
        hideKeyPad();
        return null;
      }

      const keyPadMoneyInputValue = layout.keyPadMoneyInput?.value || '';
      const fakeInputText = layout.keyPadMoneyInput?.fakeInput?.textContent;
      const amount = parseFloat(keyPadMoneyInputValue?.replace(/[^\d.]/g, '')) * 100 || 0;
      const isDotLast = fakeInputText.endsWith('.');
      const decimalCount = getDecimalCount(fakeInputText);
      let newAmount = null;
      let skipUpdateFakeInput = false;

      // console.log('444 KeyPad btn click: ', value, keyPadMoneyInputValue, amount, decimalCount, fakeInputText);

      if (decimalCount === 2 && value !== 'Backspace' && value < 10) {
        // ignore if we have 2 decimals and no Backspace
        return null;
      }

      if (
        layout.keyPadMoneyInput?.maxLength
        && value !== 'Backspace'
        && layout.keyPadMoneyInput?.maxLength > 0
        && fakeInputText.length >= layout.keyPadMoneyInput?.maxLength
      ) {
        return null;
      }

      if (value === '.') {
        layout.keyPadMoneyInput?.setFakeInputText(`${fakeInputText}.`);
        return null; // no change real value, just view
      }

      if (value === 0 && (isDotLast || decimalCount || (!amount && fakeInputText.length <= 1))) {
        // press 0 for decimal part or empty value
        layout.keyPadMoneyInput?.setFakeInputText(`${fakeInputText}0`);
        return null;
      }

      if (value >= 0 && value < 10) {
        // number
        if (isDotLast && decimalCount === 0) {
          newAmount = amount + value * 10;
        } else if (decimalCount === 1) {
          newAmount = amount + value;
        } else {
          newAmount = amount ? amount * 10 + value * 100 : value * 100;
        }
      } else if (value >= 10) {
        // +10 | +20 | +50
        newAmount = amount ? amount + value * 100 : value * 100;
      } else if (value === 'Backspace') {
        if (decimalCount === 0) {
          if (isDotLast) {
            // 1. [100] -> remove .
            layout.keyPadMoneyInput?.setFakeInputText(fakeInputText.slice(0, -1));
            return null;
          }
          if (fakeInputText.length <= 2) {
            // 0
            layout.keyPadMoneyInput?.setFakeInputText(fakeInputText.slice(0, -1));
            skipUpdateFakeInput = true; // do not show 0
          }
          // 123
          newAmount = Math.floor(amount / 1000) * 100;
        } else if (decimalCount === 1) {
          // 1.2 [120] -> 1.
          if (fakeInputText.endsWith('0')) {
            // 1.0 [100] -> 1.
            layout.keyPadMoneyInput?.setFakeInputText(fakeInputText.slice(0, -1));
            return null; // just remove decimal 0
          }
          skipUpdateFakeInput = true; // show . in the end => 1.
          layout.keyPadMoneyInput?.setFakeInputText(fakeInputText.slice(0, -1));
          newAmount = Math.floor(amount / 100) * 100;
          // newIsDot = true;
        } else if (decimalCount === 2) {
          // 1.23 [123] -> 1.2
          if (fakeInputText.endsWith('0')) {
            // 1.20 [120] -> 1.2
            layout.keyPadMoneyInput?.setFakeInputText(fakeInputText.slice(0, -1));
            return null; // just remove decimal 0
          }
          skipUpdateFakeInput = true; // 1.02 -> 1.0
          layout.keyPadMoneyInput?.setFakeInputText(fakeInputText.slice(0, -1));

          newAmount = Math.floor(amount / 10) * 10;
        }
      } else {
        return null;
      }

      layout.keyPadMoneyInput?.onChangeByKeyPad(newAmount, skipUpdateFakeInput);
    },
    [layout.keyPadMoneyInput],
  );

  React.useEffect(() => {
    if (layout.keyPadMoneyInput?.readOnly) {
      hideKeyPad();
    }
  }, [layout.keyPadMoneyInput?.readOnly, hideKeyPad]);

  React.useEffect(
    // add keyPress handling
    () => {
      if (layout.mobileDevice && layout.keyPadMoneyInput && hasSelectedParent) {
        const listener = (event) => {
          if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(event.key)) {
            onBtnClick(parseInt(event.key));
          } else if (['.', 'Backspace'].includes(event.key)) {
            onBtnClick(event.key);
          } else if (event.key === 'Enter') {
            hideKeyPad();
          }
        };

        document.addEventListener('keydown', listener);

        return () => {
          document.removeEventListener('keydown', listener);
        };
      }
    },

    [onBtnClick, hideKeyPad, layout, hasSelectedParent],
  );

  useOutsideClick(ref, (e) => {
    if (!e.target.classList.contains('fake-input') && hasSelectedParent) {
      // fix jumping: check if no need KayPad again
      hideKeyPad();
    }
  });

  if (!layout.keyPadMoneyInput || !hasSelectedParent) {
    // console.log(4448, layout.keyPadMoneyInput);
    return null;
  }

  return (
    <div className={`keypad__container ${fixed ? 'keypad__container--fixed' : ''}`} ref={ref}>
      <div className='keypad__row'>
        <button
          tabIndex='-1'
          type='button'
          className='btn btn-lg keypad__btn'
          onClick={() => onBtnClick(1)}
        >
          1
        </button>
        <button
          tabIndex='-1'
          type='button'
          className='btn btn-lg keypad__btn'
          onClick={() => onBtnClick(2)}
        >
          2
        </button>
        <button
          tabIndex='-1'
          type='button'
          className='btn btn-lg keypad__btn'
          onClick={() => onBtnClick(3)}
        >
          3
        </button>
        <button
          tabIndex='-1'
          type='button'
          className='btn btn-lg keypad__btn'
          onClick={() => onBtnClick(10)}
        >
          +$10
        </button>
      </div>
      <div className='keypad__row'>
        <button
          tabIndex='-1'
          type='button'
          className='btn btn-lg keypad__btn'
          onClick={() => onBtnClick(4)}
        >
          4
        </button>
        <button
          tabIndex='-1'
          type='button'
          className='btn btn-lg keypad__btn'
          onClick={() => onBtnClick(5)}
        >
          5
        </button>
        <button
          tabIndex='-1'
          type='button'
          className='btn btn-lg keypad__btn'
          onClick={() => onBtnClick(6)}
        >
          6
        </button>
        <button
          tabIndex='-1'
          type='button'
          className='btn btn-lg keypad__btn'
          onClick={() => onBtnClick(20)}
        >
          +$20
        </button>
      </div>
      <div className='keypad__row'>
        <button
          tabIndex='-1'
          type='button'
          className='btn btn-lg keypad__btn'
          onClick={() => onBtnClick(7)}
        >
          7
        </button>
        <button
          tabIndex='-1'
          type='button'
          className='btn btn-lg keypad__btn'
          onClick={() => onBtnClick(8)}
        >
          8
        </button>
        <button
          tabIndex='-1'
          type='button'
          className='btn btn-lg keypad__btn'
          onClick={() => onBtnClick(9)}
        >
          9
        </button>
        <button
          tabIndex='-1'
          type='button'
          className='btn btn-lg keypad__btn'
          onClick={() => onBtnClick(50)}
        >
          +$50
        </button>
      </div>
      <div className='keypad__row'>
        <button
          tabIndex='-1'
          type='button'
          className='btn btn-lg keypad__btn'
          onClick={() => onBtnClick('.')}
        >
          <strong>.</strong>
        </button>
        <button
          tabIndex='-1'
          type='button'
          className='btn btn-lg keypad__btn'
          onClick={() => onBtnClick(0)}
        >
          0
        </button>
        <button
          tabIndex='-1'
          type='button'
          className='btn btn-lg keypad__btn'
          onClick={() => onBtnClick('Backspace')}
        >
          <span className='svg-delete-left' />
        </button>
        <button
          tabIndex='-1'
          type='button'
          className='btn btn-lg keypad__btn'
          onClick={() => hideKeyPad()}
        >
          Done
        </button>
      </div>
    </div>
  );
};

export default KeyPad;
