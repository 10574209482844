import React, { useCallback, useEffect, useRef } from 'react';
import cx from 'classnames';
import { get, isFinite, round } from 'lodash';
import { NumericFormat } from 'react-number-format';
import { useApplicationState } from '@apollo/core';
import useTranslate from '../../I18n/Interpreter';
import Label from '../../I18n/Label';
import FakeInput from '../MoneyInput/FakeInput';

const PercentageInput = ({
  value,
  disabled,
  touched,
  readOnly,
  autofocus,
  focused,
  errors,
  active,
  label,
  placeholder,
  name,
  amountIcon,
  isRequiredSymbol,
  uiTitleClassName,
  className,
  withTranslate,
  onChange,
  onFocus,
  onBlur,
  combos,
}) => {
  const resetTimerRef = useRef();
  const hasFocusRef = useRef(false);

  const inputRef = useRef();

  const { layout } = useApplicationState();

  const changeHandler = useCallback(
    ({ floatValue }) => {
      const newValue = isFinite(floatValue) ? round(floatValue * 100) / 100 : null;

      clearTimeout(resetTimerRef.current);

      if (hasFocusRef.current || inputRef.current === layout.keyPadMoneyInput) {
        // update value if we edit percentage input
        resetTimerRef.current = window.setTimeout(() => {
          onChange(newValue);
        }, 500);
      }
    },
    [onChange, layout.keyPadMoneyInput],
  );

  const t = useTranslate();

  useEffect(() => clearTimeout(resetTimerRef.current), []);

  const handleFocus = () => {
    hasFocusRef.current = true;
    if (onFocus) {
      onFocus();
    }
  };

  const handleBlur = () => {
    hasFocusRef.current = false;
    if (onBlur) {
      onBlur();
    }
  };

  const error = touched ? get(errors, '[0].message') : null;
  // const warning = touched ? get(warnings, '[0].message') : null;
  // const message = error || warning;
  const inputValue = isFinite(value) ? value / combos : '';

  const fieldClassName = cx(
    'row',
    touched && 'row-touched',
    error && 'row-error',
    active && 'row-active',
    disabled && 'row-disabled',
    className,
  );
  const inputClassName = cx('ui__field');

  useEffect(() => {
    if (inputRef.current && focused) {
      inputRef.current.focus();
    }
  }, [inputRef, autofocus, focused]);

  const containerClassName = cx([
    'block block--input-money block--input-percentage',
    layout.mobileDevice && 'block--input-money--mobile',
    value && 'has-value',
    active && 'active',
  ]);

  return (
    <div className={containerClassName}>
      {label && (
        <div className='ui__title'>
          {withTranslate ? <Label message={label} /> : label}
          {isRequiredSymbol && <span className={`${uiTitleClassName} required`}> *</span>}
        </div>
      )}

      <div className='block__wrap'>
        <label className={fieldClassName}>
          {amountIcon && (
            <div className='amountIcon'>
              <span>%</span>
            </div>
          )}
          <FakeInput
            disabled={disabled}
            readOnly={readOnly}
            focused={focused}
            onChange={(newValue) => {
              onChange(isFinite(newValue) ? newValue / 100 : '');
            }}
            inputValue={inputValue || 0}
            setClicked={() => {}}
            inputRef={inputRef}
            emptyValue='% '
          />
          <NumericFormat
            getInputRef={inputRef}
            name={name}
            error={error}
            value={inputValue}
            placeholder={placeholder ? t(placeholder) : ''}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onValueChange={changeHandler}
            autoFocus={autofocus}
            // touched={touched}
            active={active}
            disabled={disabled}
            label={label}
            prefix='% '
            decimalScale={2}
            allowNegative={false}
            thousandSeparator
            className={inputClassName}
            type='tel'
          />
        </label>
      </div>
    </div>
  );
};

export default PercentageInput;
