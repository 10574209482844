import React, { useMemo, useState } from 'react';
import { useApplicationState } from '@apollo/core';
import { isEmpty, map } from 'lodash';
import { useRacingState } from '@apollo/core/src/state/racing/racing';
import Label from '../../../../shared/components/I18n/Label';
import useTranslate from '../../../../shared/components/I18n/Interpreter';
import { RACE_STATUS } from '../../../../core/constants';
import MultilegsOdds from './Odds';
import { getFavOddRunner, getSortedRunners } from '../../../../core/utils';
import Selections from './Selections';
import RaceRunnerRow from '../RaceRunnerRow';
import RaceFlucs from '../../raceFlucs/RaceFlucs';
import RaceFlucsHeader from '../../raceFlucs/RaceFlucsHeader';

const MultilegsMarketPage = ({
  runners,
  legs,
  selectedBetType,
  raceStatic,
  displaySettings = {},
}) => {
  const t = useTranslate();
  const { layout } = useApplicationState();
  const [racingState] = useRacingState();
  const { activeRace, outcomesByRunnerId } = racingState;
  const { isFlucs, isSpeedMap, isForm } = displaySettings;

  const favOddRunner = getFavOddRunner(runners, outcomesByRunnerId);

  const [sortByField, setSortByField] = useState({
    field: 'runner',
    asc: true,
  });

  const onSort = (field) => {
    setSortByField({
      field,
      asc: sortByField.field === field ? !sortByField.asc : true,
    });
  };

  const sortedRunners = useMemo(() => {
    const sortedRunners = getSortedRunners(
      runners,
      outcomesByRunnerId,
      sortByField.field,
      sortByField.asc,
    );
    return sortedRunners.sort((a, b) => a.scratched - b.scratched);
  }, [runners, sortByField, outcomesByRunnerId]);

  const hasFixedWinOdds = useMemo(
    () => outcomesByRunnerId
      && Object.keys(outcomesByRunnerId).some((runnerId) => outcomesByRunnerId[runnerId].F_WIN?.odds),
    [outcomesByRunnerId],
  );

  return (
    <div className='miltilegs_wrapper'>
      <div className='tournament tournament--classic racelist racelist--miltilegs'>
        <div className='tournament__header'>
          <div className='event event--classic'>
            <div className='event__main-period'>
              <div className='event__info'>
                <div className='event-participants'>
                  <div
                    className={`event-participants__participant sorting ${
                      sortByField.field === 'runner' && (sortByField.asc ? 'asc' : 'desc')
                    }`}
                    onClick={() => onSort('runner')}
                  >
                    <Label message='Runner' />
                  </div>
                </div>
              </div>

              <RaceFlucsHeader />

              <div
                className={`event__market-row
                ${layout.mobileDevice ? 'mobile__values' : 'desktop__values'}`}
              >
                <div className='odds-with-selected-label'>
                  <div className='selected-field-label event__market-row-market'>
                    <div className='selected-field-label'>{t('#exotics_select_field')}</div>
                  </div>
                  {!isEmpty(runners) && (
                    <MultilegsOdds race={activeRace} runners={runners} legs={legs} />
                  )}
                </div>
                {hasFixedWinOdds && (
                  <span
                    className={`sorting ${
                      sortByField.field === 'F_WIN' && (sortByField.asc ? 'asc' : 'desc')
                    }`}
                    onClick={() => onSort('F_WIN')}
                  >
                    {t('Win')}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='event-group event-group--races'>
          {!isEmpty(sortedRunners)
            && map(sortedRunners, (r) => (
              <RaceRunnerRow
                key={r.runnerId}
                runner={r}
                runnerStatic={raceStatic?.runners?.find((runner) => runner.runnerId === r.runnerId)}
                activeRace={activeRace}
                isFlucs={isFlucs}
                isForm={isForm}
                isSpeedMap={isSpeedMap}
              >
                <RaceFlucs activeRace={activeRace} runner={r} />

                <div className='event__market-row'>
                  {!r.scratched ? (
                    <MultilegsOdds
                      race={activeRace}
                      runner={r}
                      legs={legs}
                      odds={outcomesByRunnerId?.[r.runnerId]?.F_WIN?.odds}
                      favorite={
                        activeRace.status === RACE_STATUS.OPEN && favOddRunner === r.runnerId
                      }
                    />
                  ) : null}
                </div>
              </RaceRunnerRow>
            ))}
        </div>
        {!isEmpty(sortedRunners) ? (
          <Selections legs={legs} selectedBetType={selectedBetType} />
        ) : null}
      </div>
    </div>
  );
};

export default MultilegsMarketPage;
