import React, { useMemo } from 'react';
import SpeedMapItem from './SpeedMapItem';
import { RaceRunnerImage } from '../RaceRunnerImage/RaceRunnerImage';
import Label from '../../../../shared/components/I18n/Label';
import useTranslate from '../../../../shared/components/I18n/Interpreter';

const SPEED_BANDS = ['BACKMARKER', 'MIDFIELD', 'PACE', 'LEADER', 'NO DATA'];

const SpeedMap = ({ activeRace, runners = [] }) => {
  const t = useTranslate();

  const speedBands = useMemo(
    () => [
      ...new Set(
        runners.map(({ staticInfo }) => staticInfo?.earlySpeedBand || t('earlySpeedNoData')),
      ),
    ],
    [runners, t],
  );

  return runners.length ? (
    <div className={`speed-map speed-map--${activeRace?.type?.toLowerCase()}`}>
      <h3 className='speed-map__title'>
        <Label message='race speed map' />
      </h3>
      <div className='speed-map__legend'>
        {speedBands.map((band) => (
          <div
            key={band}
            className={`speed-label speed-label--${band.toLowerCase()} dot-band dot-band--${band.toLowerCase()}`}
          >
            {band}
          </div>
        ))}
      </div>
      {runners
        .filter((runner) => !runner.scratched)
        .map((runner) => (
          <div className='speed-map__runner' key={runner.runnerId}>
            <div className='event-participants__participant race'>
              <span className='event-participants__participant-index'>{runner.number}</span>
              <RaceRunnerImage runner={runner} masked={activeRace?.type !== 'DOG'} />
              {/* <span className='event-participants__participant-name race bold'>
            {runner.name}
          </span> */}
            </div>

            <SpeedMapItem runnerStatic={runner} label={`${runner.number}. ${runner.name}`} />
          </div>
        ))}
      <div className='speed-map__barrier-info barrier-info'>
        <div className='barrier-info__start'>
          <Label className='barrier-info__label' message='barrier' />
        </div>
        <div className='barrier-info__middle' />
        <div className='barrier-info__end'>
          <span className='barrier-info__label'>{`${activeRace.distance}m`}</span>
        </div>
      </div>
    </div>
  ) : null;
};

export default SpeedMap;
