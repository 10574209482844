import React, { useEffect, useRef, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import classNames from 'classnames';

const Scrollbar = ({
  children,
  scrollClassName = '',
  className = '',
  scrollable = true,
  draggable = true,
  navigationButtons = false,
  ...innerProps
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const scrollbarsRef = useRef(null);
  const nextRaceListRef = useRef(null);
  const intervalRef = useRef(null);
  const mouseMouseDownXRef = useRef(null);
  const startScrollXRef = useRef(null);
  const [isScrollLeftDisabled, setIsScrollLeftDisabled] = useState(true);
  const [isScrollRightDisabled, setIsScrollRightDisabled] = useState(true);

  const getVisibleScrollWidth = () => nextRaceListRef.current.offsetWidth;

  const scrollLeftDisabled = () => scrollbarsRef.current?.getScrollLeft() === 0;
  const scrollRightDisabled = () => scrollbarsRef.current?.getScrollWidth()
    <= parseInt(scrollbarsRef.current?.getScrollLeft())
      + parseInt(nextRaceListRef.current?.offsetWidth);

  const setScrollStatus = () => {
    setIsScrollLeftDisabled(scrollLeftDisabled());
    setIsScrollRightDisabled(scrollRightDisabled());
  };

  const handleScroll = (delta) => {
    // scroll with navigation buttons or mouse Wheel
    if (scrollbarsRef.current && !intervalRef.current) {
      const visibleScrollWidth = getVisibleScrollWidth();
      const startScrollLeft = scrollbarsRef.current.getScrollLeft();
      let targetScrollLeft = startScrollLeft + delta;

      if ((delta < 0 && scrollLeftDisabled()) || (delta > 0 && scrollRightDisabled())) {
        return;
      }

      targetScrollLeft = Math.max(
        0,
        Math.min(targetScrollLeft, scrollbarsRef.current.getScrollWidth() - visibleScrollWidth),
      );

      const step = delta < 0 ? -10 : 10;
      const duration = 10;

      intervalRef.current = setInterval(() => {
        const currentScrollLeft = scrollbarsRef.current.getScrollLeft();
        const newScrollLeft = currentScrollLeft + step;

        scrollbarsRef.current.scrollLeft(newScrollLeft);

        if (
          (delta > 0 && newScrollLeft >= targetScrollLeft)
          || (delta < 0 && newScrollLeft <= targetScrollLeft)
        ) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
          setScrollStatus();
        }

        if (
          (delta < 0 && newScrollLeft <= 0)
          || (delta > 0
            && newScrollLeft >= scrollbarsRef.current.getScrollWidth() - visibleScrollWidth)
        ) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
          setScrollStatus();
        }
      }, duration);
    }
  };

  const handleDragScroll = (delta) => {
    if (scrollbarsRef.current) {
      const visibleScrollWidth = getVisibleScrollWidth();
      const startScrollLeft = scrollbarsRef.current.getScrollLeft();

      if (
        (delta < 0 && startScrollLeft === 0)
        || (delta > 0
          && startScrollLeft === scrollbarsRef.current.getScrollWidth() - visibleScrollWidth)
      ) {
        return;
      }

      const newScrollLeft = startScrollXRef.current + delta;

      scrollbarsRef.current.scrollLeft(newScrollLeft);
    }
  };

  const handleScrollRight = () => {
    handleScroll(getVisibleScrollWidth());
  };

  const handleScrollLeft = () => {
    handleScroll(-getVisibleScrollWidth());
  };

  const handleMouseMove = (event) => {
    const delta = mouseMouseDownXRef.current ? mouseMouseDownXRef.current - event.clientX : 0;
    // console.log(770, delta);

    setIsDragging(true); // to prevent click on link when mouseUp
    /*     if (Math.abs(delta) > 5) {
      // skip minor movement
      console.log(779, isDragging);
    } */

    handleDragScroll(delta);
  };

  const handleMouseUp = () => {
    mouseMouseDownXRef.current = null;
    startScrollXRef.current = null;
    setIsDragging(false);
    document.removeEventListener('mouseup', handleMouseUp);
    document.removeEventListener('mousemove', handleMouseMove);
    setScrollStatus();
  };

  const handleMouseDown = (event) => {
    if (!draggable) {
      return;
    }

    mouseMouseDownXRef.current = event.clientX;
    startScrollXRef.current = scrollbarsRef.current.getScrollLeft();

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  useEffect(
    () => () => {
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousemove', handleMouseMove);
    },
    [],
  );

  useEffect(() => {
    setScrollStatus();
  }, [children]);

  return (
    <>
      <div
        className={className}
        ref={nextRaceListRef}
        onWheel={(e) => scrollable && handleScroll(e.deltaY)}
        onMouseDown={draggable && handleMouseDown}
      >
        <Scrollbars
          {...innerProps}
          className={classNames('scroll__container', scrollClassName, {
            'scroll__container--dragging': isDragging,
          })}
          ref={scrollbarsRef}
        >
          {children}
        </Scrollbars>
      </div>
      {navigationButtons ? (
        <div className='scroll__navigation-buttons next-race-nav'>
          <button
            type='button'
            className={`next-race-nav__button ${isScrollRightDisabled ? 'next-race-nav__button--disabled' : ''}`}
            onMouseDown={handleScrollRight}
          >
            <span className='icon AIcon-angle-right' />
          </button>
          <button
            type='button'
            className={`next-race-nav__button ${isScrollLeftDisabled ? 'next-race-nav__button--disabled' : ''}`}
            onClick={handleScrollLeft}
          >
            <span className='icon AIcon-angle-left' />
          </button>
        </div>
      ) : null}
    </>
  );
};

export default Scrollbar;
