import React from 'react';
import { JumpingCoinAnimationProvider } from './JumpingCoinAnimation';
import { ParticleAnimationProvider } from './ParticleAnimation';
import { TextAnimationProvider } from './TextAnimation';
import { GiftBoxAnimationProvider } from './GiftBoxAnimation';

const contexts = [ParticleAnimationProvider, TextAnimationProvider, JumpingCoinAnimationProvider, GiftBoxAnimationProvider];

const AnimationsProvider = ({ children }) => contexts.reduceRight((acc, Comp) => <Comp>{acc}</Comp>, children);

export default AnimationsProvider;
