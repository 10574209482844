import React, { useEffect, useRef, useState } from 'react';
import { Bus, DedicatedEventProvider, isFeedOffline, useBetslipState } from '@apollo/core';
import { SportSelector, useSportState } from '@apollo/core/src/state/sport/sport';
import DetailPageEvent from './DetailPageEvent';
import Label from '../../../shared/components/I18n/Label';
import { SgmProvider } from '../../../state/Sgm/SgmState';
import SportLoader from '../SportLoader';
import SportNothingFound from '../SportNothingFound';

const DetailsPage = () => {
  const [sportsState] = useSportState();
  const { selectedSportId, selectedEventId, loading } = sportsState;
  const selectedSport = SportSelector.selectSportById(selectedSportId);
  const selectedEvent = SportSelector.selectCurrentEvent();

  const {
    settings: { feedStates },
  } = useBetslipState();

  useEffect(() => {
    if (selectedEventId) {
      Bus.emit(Bus.events.sport.sportLoadSingleEvent, {
        eventId: selectedEventId,
        marketGroup: 'MAIN',
      });
    }
  }, [selectedEventId]);

  // The default sport loading state is 'false'.
  // This results in the "Nothing Found" text to display instead of the loading icon
  const timeoutRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      setIsLoading(loading);
    }, 250);
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [loading]);

  if (!selectedEvent) {
    if (isLoading) {
      return <SportLoader />;
    }
    return <SportNothingFound className='nothing-found--details' selectedSport={selectedSport} />;
  }

  // todo: I don't think this will work. We'll need a better "Event not found" system.
  if (selectedEvent?.error) {
    return (
      <div className='sportDetails animate-fade-left-in'>
        <Label message='event_not_found' />
      </div>
    );
  }

  const isEventFeedOffline = isFeedOffline(feedStates[selectedEvent.source]);

  return (
    <DedicatedEventProvider event={selectedEvent}>
      <SgmProvider>
        <DetailPageEvent event={selectedEvent} suspended={isEventFeedOffline} />
      </SgmProvider>
    </DedicatedEventProvider>
  );
};

export default DetailsPage;
