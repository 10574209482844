import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { isFinite, round } from 'lodash';

import { useApplicationState } from '@apollo/core';
import { NumericFormat } from 'react-number-format';
import useTranslate from '../../I18n/Interpreter';
import Label from '../../I18n/Label';
import ErrorBlock from '../Input/ErrorBlock';
import { getCurrencySymbol } from '../../../../core/utils';
import FakeInput from './FakeInput';

const MoneyInput = ({
  value,
  type,
  disabled,
  readOnly,
  touched,
  autofocus,
  focused,
  errors = [],
  active,
  label,
  placeholder,
  name,
  amountIcon,
  isRequiredSymbol,
  uiTitleClassName,
  className,
  withTranslate,
  onChange,
  onFocus,
  success,
  onBlur,
}) => {
  const t = useTranslate();

  const {
    account: { currency },
    layout,
  } = useApplicationState();

  const inputRef = useRef();

  const [clicked, setClicked] = useState(false);

  const currencySymbol = getCurrencySymbol(currency);

  const changeHandler = useCallback(
    ({ floatValue }) => {
      const newValue = isFinite(floatValue) ? round(floatValue * 100) : null;
      onChange(newValue);
    },
    [onChange],
  );

  const error = touched || (clicked && inputRef.current !== layout.keyPadMoneyInput) ? errors?.[0] : null;
  // const warning = touched ? get(warnings, '[0].message') : null;
  // const message = error || warning;

  /**
   * Why this and not the standard (above)...?
  const error = clicked
  && inputRef.current !== layout.keyPadMoneyInput
  && errors[0] ? errors[0].message : null;
  */

  const inputValue = useMemo(
    // cents to dollar
    () => (isFinite(value) ? value / 100 : value),
    [value],
  );

  const fieldClassName = cx(
    'row',
    touched && 'row-touched',
    error && 'row-error',
    active && 'row-active',
    disabled && 'row-disabled',
    className,
  );
  const inputClassName = cx('ui__field');

  useEffect(() => {
    if (inputRef.current && focused) {
      inputRef.current.focus();
    }
  }, [inputRef, autofocus, focused]);

  const containerClassName = cx([
    'block block--input-money',
    layout.mobileDevice && 'block--input-money--mobile',
    error && 'error',
    success && 'success',
    value && 'has-value',
    active && 'active',
  ]);

  return (
    <div className={containerClassName}>
      {label && (
        <div className='ui__title'>
          {withTranslate ? <Label message={label} /> : label}
          {isRequiredSymbol && <span className={`${uiTitleClassName} required`}> *</span>}
        </div>
      )}

      <div className='block__wrap'>
        <label className={fieldClassName}>
          {amountIcon && (
            <div className='amountIcon'>
              <span>{currencySymbol}</span>
            </div>
          )}
          <FakeInput
            disabled={disabled}
            readOnly={readOnly}
            focused={focused}
            onChange={onChange}
            inputValue={inputValue}
            setClicked={setClicked}
            inputRef={inputRef}
            emptyValue={currencySymbol}
          />
          {/* <span
            ref={fakeInputRef}
            className={cx(
              'fake-input ui__field',
              inputRef.current === layout.keyPadMoneyInput && 'active',
              readOnly && 'readonly',
            )}
            onClick={() => (!disabled && !readOnly ? setKeyPadMoneyInput(inputRef.current) : null)}
          >
            {fakeInputText || currencySymbol}
          </span> */}
          <NumericFormat
            getInputRef={inputRef}
            name={name}
            error={error}
            value={inputValue}
            placeholder={placeholder ? t(placeholder) : ''}
            onBlur={onBlur}
            onFocus={onFocus}
            onValueChange={changeHandler}
            autoFocus={autofocus}
            // touched={touched}
            // active={active}
            disabled={disabled}
            readOnly={readOnly}
            label={label}
            prefix={currencySymbol}
            decimalScale={2}
            allowNegative={false}
            thousandSeparator
            success={success}
            className={inputClassName}
            maxLength={isFinite(value) && value % 100 === 0 ? 10 : 11} // $999,999.1 || $999,999.99
            type={type || 'tel'}
          />
        </label>
        <ErrorBlock
          withTranslate={withTranslate}
          error={error}
          // errors={[]}
          // rules={rules}
        />
      </div>
    </div>
  );
};

export default MoneyInput;
