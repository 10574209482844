import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import { Bus, useApplicationState } from '@apollo/core';

const FakeInput = ({
  inputValue,
  emptyValue,
  inputRef,
  disabled,
  readOnly,
  focused,
  onChange,
  setClicked,
}) => {
  const { layout } = useApplicationState();

  const fakeInputRef = useRef(); // use <span> for mobile
  const skipUpdateFakeInputRef = useRef(false); // ignore update for fakeInput

  const [fakeInputText, setFakeInputText] = useState(inputValue); // it's value to display in fakeInput

  useEffect(() => {
    if (!skipUpdateFakeInputRef.current) {
      setFakeInputText(inputRef.current?.value);
      skipUpdateFakeInputRef.current = false;
    }
  }, [inputValue, inputRef]);

  const setKeyPadMoneyInput = (input) => {
    // initialize KeyPad MoneyInput
    setClicked(true);
    const keyPadMoneyInput = input;
    keyPadMoneyInput.onChangeByKeyPad = (val, skipUpdateFakeInput) => {
      skipUpdateFakeInputRef.current = !!skipUpdateFakeInput;
      // call default change handler for KeyPad changes
      onChange(val);
    };
    keyPadMoneyInput.setFakeInputText = setFakeInputText; // to change fake input text directly
    keyPadMoneyInput.fakeInput = fakeInputRef.current;

    Bus.send({
      event: Bus.events.layout.setLayout,
      data: {
        ...layout,
        keyPadMoneyInput,
      },
    });

    // scroll into view curren input
    window.setTimeout(
      () => fakeInputRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      }),
      100,
    );
  };

  useEffect(() => {
    if (!disabled && !readOnly && focused) {
      setKeyPadMoneyInput(inputRef.current);
    }
  }, [disabled, readOnly, focused]);

  return (
    <span
      ref={fakeInputRef}
      className={cx(
        'fake-input ui__field',
        inputRef.current === layout.keyPadMoneyInput && 'active',
        readOnly && 'readonly',
      )}
      onTouchEnd={() => {
        // onTouchEnd instead of onClick to prevent open again
        !disabled && !readOnly ? setKeyPadMoneyInput(inputRef.current) : null;
      }}
    >
      {fakeInputText || emptyValue}
    </span>
  );
};

export default FakeInput;
