import React, { useCallback, useMemo } from 'react';
import { centsToDecimal, DateService, useApplicationState } from '@apollo/core';
import { at } from 'lodash';
import { getCurrencySymbol } from '../../../core/utils';
import Label from '../../../shared/components/I18n/Label';
import { transactionTypes } from '../../../core/constants';
import TransactionCancelButton from '../HistoryTab/TransactionHistory/TableList/TransactionCancelButton';

const PendingWithdrawal = ({ transaction, onCancelWithdrawal }) => {
  const { id, type, creationDate, currency, amount } = transaction;
  const applicationState = useApplicationState();

  const [region] = at(applicationState, ['account.region']);

  const disableCancel = useMemo(() => !region || region.toUpperCase() === 'SA', [region]);

  const date = DateService.getFormattedDate(creationDate);
  const time = DateService.getFormattedTime(creationDate);
  const currencySymbol = getCurrencySymbol(currency);

  const cancelWithdrawalHandler = useCallback(() => {
    onCancelWithdrawal(id);
  }, [id]);

  return (
    <div className='transaction-item'>
      <div className='transaction-item-cell transaction-date'>
        <span>{date}</span>
        <span className='sub-title'>{time}</span>
      </div>
      <div className='transaction-item-cell transaction-description'>
        <Label message={type} />
        <span className='sub-title'>{id}</span>
      </div>
      <div className='transaction-item-cell transaction-amount'>{`${currencySymbol}${centsToDecimal(amount)}`}</div>
      {!disableCancel && type === transactionTypes.WITHDRAWAL_CREATE && (
        <div className='transaction-item-cell transaction-action'>
          <TransactionCancelButton onCancel={cancelWithdrawalHandler} />
        </div>
      )}
    </div>
  );
};

export default PendingWithdrawal;
