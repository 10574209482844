import React from 'react';
import ProgressBar from './ProgressBar';

const BonusBetTracker = ({ eligibilityStats, topLabelProgressBar = false }) => {
  const { requiredBetsCount = 0, eligibleBetsCount = 0 } = eligibilityStats;
  const required = requiredBetsCount;
  const current = Math.min(eligibleBetsCount, required);

  if (!required) {
    return null;
  }

  return (
    <div className='bonus-eligibility-limit bonus-eligibility-limit--bets'>
      <ProgressBar
        current={current}
        required={required}
        tooltip={`${current} out of ${required}`}
        titleProgress='bonus_bets_count'
        titleComplete='bonus_bets_complete'
        topLabel={topLabelProgressBar}
      />
    </div>
  );
};

export default React.memo(BonusBetTracker);
