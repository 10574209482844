import { useCallback, useEffect, useRef, useState } from 'react';

const useConditionalLoading = ({ loading = true, defaultState = true }) => {
  const [isLoading, _setIsLoading] = useState(defaultState);

  const setIsLoading = useCallback(
    (loading) => {
      if (isLoading !== loading) {
        _setIsLoading(loading);
      }
    },
    [isLoading],
  );

  const timerRef = useRef(null);
  useEffect(() => {
    timerRef.current = setTimeout(() => {
      if (isLoading !== loading) {
        _setIsLoading(loading);
      }
    }, 250);
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [isLoading, loading]);

  return [isLoading, setIsLoading];
};

export default useConditionalLoading;
