import React, { useMemo } from 'react';
import { generatePath, Link, useLocation } from 'react-router-dom';
import { SiteConfigManager } from '@apollo/core';
import { COMPONENT_TYPES } from '@apollo/routing';
import { SportSelector, useSportState } from '@apollo/core/src/state/sport/sport';
import { at, isNil } from 'lodash';
import Label from '../../shared/components/I18n/Label';
import { sportService } from '../../core/constants';

const { PREMATCH, LIVE } = sportService;

const SportBreadcrumbs = () => {
  const [sportsState] = useSportState();
  const location = useLocation();
  const {
    selectedSportId,
    selectedSportUrlName,
    selectedCategoryUrlName,
    selectedTournamentId,
    selectedTournamentUrlName,
    selectedEventId,
  } = sportsState;

  const selectedSport = SportSelector.selectSportById(selectedSportId);
  const selectedTournament = SportSelector.selectTournamentById(selectedTournamentId);
  const selectedEvent = SportSelector.selectEventById(selectedEventId);

  const [sportService, homeFullName, awayFullName, homeScore, awayScore] = at(selectedEvent, [
    'sportService',
    'participants.home.fullName',
    'participants.away.fullName',
    'info.score.homeScore',
    'info.score.awayScore',
  ]);

  const prematchPath = SiteConfigManager.getComponentPath(COMPONENT_TYPES.SPORT_PREMATCH);
  const livePath = SiteConfigManager.getComponentPath(COMPONENT_TYPES.SPORT_LIVE);

  const homePath = useMemo(
    () => (sportService === LIVE ? livePath : prematchPath),
    [prematchPath, livePath, sportService],
  );

  const sportPath = React.useMemo(
    () => selectedSportUrlName
      && generatePath(`${homePath}/:sportName`, {
        sportName: decodeURIComponent(selectedSportUrlName || '') || null,
      }),
    [homePath, selectedSportUrlName],
  );

  const tournamentPath = React.useMemo(
    () => sportPath
      && selectedCategoryUrlName
      && selectedTournamentUrlName
      && generatePath(`${sportPath}/:categoryUrlName/:tournamentUrlName`, {
        categoryUrlName: decodeURIComponent(selectedCategoryUrlName || '') || null,
        tournamentUrlName: decodeURIComponent(selectedTournamentUrlName || '') || null,
      }),
    [sportPath, selectedCategoryUrlName, selectedTournamentUrlName],
  );

  const isActive = (path) => {
    const currentPath = location.pathname.split('#')[0].split('?')[0];
    const targetPath = path.split('#')[0].split('?')[0];

    return decodeURIComponent(currentPath) === decodeURIComponent(targetPath);
  };

  return (
    <div className='sport-markets-header breadcrumbs breadcrumbs--racing'>
      <div className='breadcrumbs__details'>
        <Link
          to='/'
          className={`breadcrumbs__crumb breadcrumbs__crumb--home${isActive('/') ? ' active' : ''}`}
        >
          <Label message='home' />
        </Link>

        <span className='breadcrumbs__seperator'>&gt;</span>
        <Link
          to={homePath}
          className={`breadcrumbs__crumb breadcrumbs__crumb--sport-home${isActive(homePath) ? ' active' : ''}`}
        >
          <Label message='all sports' />
        </Link>

        {selectedSport && (
          <>
            <span className='breadcrumbs__seperator'>&gt;</span>
            <Link
              to={sportPath}
              className={`breadcrumbs__crumb breadcrumbs__crumb--sport${isActive(sportPath) ? ' active' : ''}`}
            >
              {selectedSport.name}
            </Link>
          </>
        )}

        {selectedTournament && (
          <>
            <span className='breadcrumbs__seperator'>&gt;</span>
            <Link
              to={tournamentPath}
              className={`breadcrumbs__crumb breadcrumbs__crumb--tournament${isActive(tournamentPath) ? ' active' : ''}`}
            >
              {selectedTournament.name}
            </Link>
          </>
        )}

        {homeFullName && awayFullName && (
          <>
            <span className='breadcrumbs__seperator'>&gt;</span>
            <span className='breadcrumbs__crumb breadcrumbs__crumb--event active'>
              <span>{homeFullName}</span>
              {sportService === PREMATCH || isNil(homeScore) || isNil(awayScore) ? (
                <span className='breadcrumbs__seperator breadcrumbs__seperator--event'>@</span>
              ) : (
                <span className='breadcrumbs__seperator breadcrumbs__seperator--event'>{`${homeScore} : ${awayScore}`}</span>
              )}
              <span>{awayFullName}</span>
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export default SportBreadcrumbs;
