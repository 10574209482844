import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { DedicatedEventProvider } from '@apollo/core/src/state/event';
import Theme from 'themeSource/custom';
import OutrightEvent from '../OutrightEvent/OutrightEvent';
import EventClassic from '../Event/EventClassic';
import EventMultiMarkets from '../Event/EventMultiMarkets';
import { EVENT_LIST_TEMPLATE } from '../../../../core/utils';

const EventListItem = ({
  event,
  mainMarketTypesByPeriod,
  activeMarketTypeId,
  order,
  template,
  withSportIcon,
  countDownDisplayTimeout,
  countDownCompleteHandler,
}) => {
  const [id, outright] = _.at(event, ['id', 'outright']);

  const Component = useMemo(() => {
    if (outright) {
      return OutrightEvent;
    }

    if (template === EVENT_LIST_TEMPLATE.MULTI_MARKETS) {
      return EventMultiMarkets;
    }

    return EventClassic;
  }, [outright, template]);

  const timeoutIdToHandler = useRef(null);
  const timeoutIdToShowCountDown = useRef(null);

  const hasTimeoutBeforeDisplay = useMemo(
    () => countDownDisplayTimeout
      && moment().add(countDownDisplayTimeout, 'seconds').isAfter(moment(event.startTime)),
    [countDownDisplayTimeout, event],
  );

  const [showCountDown, setShowCountDown] = useState(hasTimeoutBeforeDisplay);

  useEffect(() => {
    // show countdown
    if (countDownDisplayTimeout) {
      const timeToShowCounter = moment(event.startTime).subtract(
        countDownDisplayTimeout,
        'seconds',
      );
      const showCounterIn = timeToShowCounter.diff(moment(), 'seconds');

      if (timeoutIdToShowCountDown.current) {
        clearTimeout(timeoutIdToShowCountDown.current);
      }

      if (showCounterIn > 0) {
        timeoutIdToShowCountDown.current = setTimeout(() => {
          setShowCountDown(true);
        }, showCounterIn * 1000);
      }

      return () => timeoutIdToShowCountDown.current && clearTimeout(timeoutIdToShowCountDown.current);
    }
  }, [countDownDisplayTimeout, event]);

  useEffect(() => {
    // run countDownCompleteHandler on startTime
    if (countDownCompleteHandler) {
      const runHandlerIn = moment(event.startTime).diff(moment(), 'seconds');

      if (timeoutIdToHandler.current) {
        clearTimeout(timeoutIdToHandler.current);
      }

      // setTimeout has a Maximum time limit.
      // Ref: https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#maximum_delay_value
      const timeoutSeconds = Math.min(2147483647, runHandlerIn * 1000);
      timeoutIdToHandler.current = setTimeout(() => {
        countDownCompleteHandler();
      }, timeoutSeconds);

      return () => timeoutIdToHandler.current && clearTimeout(timeoutIdToHandler.current);
    }
  }, [countDownCompleteHandler, event]);

  return (
    <DedicatedEventProvider event={event} key={id}>
      <Component
        event={event}
        template={template}
        className={cx([`template--${template}`, event.isSgmEnabled && `template--${template}_sgm`])}
        order={order}
        mainMarketTypesByPeriod={mainMarketTypesByPeriod}
        activeMarketTypeId={activeMarketTypeId}
        withCountDown={showCountDown}
        withSportIcon={withSportIcon}
      />
    </DedicatedEventProvider>
  );
};

export default EventListItem;
