import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SiteConfigManager } from '@apollo/core';
import { COMPONENT_TYPES } from '@apollo/routing';
import { useRacingState } from '@apollo/core/src/state/racing/racing';
import Label from '../../shared/components/I18n/Label';
import RaceLink from '../../shared/components/Racing/RaceLink';

const RacingBreadcrumbs = () => {
  const [racingState] = useRacingState();
  const { raceDate, raceType, raceCountry, raceVenue, activeMeet } = racingState;
  const location = useLocation();
  const racingPath = SiteConfigManager.getComponentPath(COMPONENT_TYPES.RACING);

  // Determine if racing home is active (when no other racing params are selected)
  const isRacingHomeActive = location.pathname === racingPath && !raceType;

  return (
    <div className='sport-markets-header breadcrumbs breadcrumbs--racing'>
      <div className='breadcrumbs__details'>
        <Link
          to='/'
          className={`breadcrumbs__crumb breadcrumbs__crumb--home ${location.pathname === '/' ? 'active' : ''}`}
        >
          <Label message='home' />
        </Link>

        <span className='breadcrumbs__seperator'>&gt;</span>
        <Link
          to={racingPath}
          className={`breadcrumbs__crumb breadcrumbs__crumb--racing-home ${isRacingHomeActive ? 'active' : ''}`}
        >
          <Label message='racing' />
        </Link>

        {raceType && (
          <>
            <span className='breadcrumbs__seperator'>&gt;</span>
            <RaceLink
              className={`breadcrumbs__crumb breadcrumbs__crumb--racing-type ${!raceCountry ? 'active' : ''}`}
              raceDate={raceDate}
              raceType={raceType}
            >
              {raceType}
            </RaceLink>
          </>
        )}

        {raceCountry && (
          <>
            <span className='breadcrumbs__seperator'>&gt;</span>
            <RaceLink
              className={`breadcrumbs__crumb breadcrumbs__crumb--racing-type ${!raceVenue ? 'active' : ''}`}
              raceDate={raceDate}
              raceType={raceType}
              raceCountry={raceCountry}
            >
              {raceCountry}
            </RaceLink>
          </>
        )}

        {raceVenue && (
          <>
            <span className='breadcrumbs__seperator'>&gt;</span>
            <RaceLink
              className='breadcrumbs__crumb breadcrumbs__crumb--racing-type active'
              raceDate={raceDate}
              raceType={raceType}
              raceCountry={raceCountry}
              raceVenue={raceVenue}
              raceNumber={activeMeet?.races ? activeMeet.races[0]?.number : 1}
            >
              {raceVenue}
            </RaceLink>
          </>
        )}
      </div>
    </div>
  );
};

export default RacingBreadcrumbs;
