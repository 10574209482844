import React, { useMemo } from 'react';
import cx from 'classnames';
import Label from '../../../../../shared/components/I18n/Label';

const TopLabelProgressBar = ({
  current = 0,
  required = 100,
  valueLabel = '',
  titleProgress = 'bonus_bets_count',
  titleComplete = 'bonus_bets_complete',
  tooltip = '',
}) => {
  const progress = useMemo(() => {
    const c = `${current}`.replace(',', '.').replaceAll(/\s/g, '');
    const r = `${required}`.replace(',', '.').replaceAll(/\s/g, '');
    return Math.min(100, r ? Math.round((c / r) * 100) : 0);
  }, [current, required]);

  const title = useMemo(() => {
    if (progress >= 100 && titleComplete) {
      return <Label message={titleComplete} />;
    }
    if (progress < 100 && titleProgress) {
      return <Label message={titleProgress} />;
    }
    return null;
  }, [progress, titleProgress, titleComplete]);

  const classes = cx(
    'top-label-progress-bar',
    `progress-bar--${progress}`,
    progress === 0 ? 'progress-bar--none' : null,
    progress < 10 ? 'progress-bar--started' : null,
    progress >= 90 ? 'progress-bar--uncomplete' : null,
    progress >= 100 ? 'progress-bar--complete' : 'progress-bar--incomplete',
  );

  return (
    <div className={classes} title={tooltip}>
      <div className='top-label-progress-bar__header'>
        <div className='top-label-progress-bar__title'>
          {title}
        </div>
        <div className='top-label-progress-bar__counts'>
          <span className='top-label-progress-bar__current'>
            {valueLabel && <span className='top-label-progress-bar__value-label'>{valueLabel}</span>}
            {current}
          </span>
          {' / '}
          <span className='top-label-progress-bar__required'>
            {valueLabel && <span className='top-label-progress-bar__value-label'>{valueLabel}</span>}
            {required}
          </span>
        </div>
      </div>
      <div className='top-label-progress-bar__bar-container'>
        <div className='top-label-progress-bar__bar' style={{ width: `${progress}%` }} />
      </div>
    </div>
  );
};

export default React.memo(TopLabelProgressBar);
