import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BetManager, Bus, useApplicationState } from '@apollo/core';
import { debounce } from 'lodash';
import { BET_EVENT_TYPE, BETSLIP_GROUP } from '@apollo/core/src/constants';
import Odds from 'src/app/shared/components/Odds';
import Theme from 'themeSource/custom';
import useSgm from '../../../../state/Sgm/SgmState';
import useTranslate from '../../../../shared/components/I18n/Interpreter';
import Loader from '../../../../shared/components/Loader/Loader';
import { formatOdds, oddsAnimation } from '../../../../core/utils';
import SelectedOddsButtons from '../../../../shared/components/SelectedOdds/SelectedOddsButtons';
import ToastManager from '../../../../core/ToastManager';

const SectionOdds = ({ event }) => {
  const t = useTranslate();
  const { layout } = useApplicationState();

  const [isExpanded, setIsExpanded] = useState(!!Theme.EXPANDED_SELECTION);
  const [isFetchingOdds, setIsFetchingOdds] = useState(false);
  const [outcome, setOutcome] = useState(null);
  const [outcomeErr, setOutcomeErr] = useState(null);

  // variable for storing latest API request Promise object,
  // which can be used to remedy racing between simultaneous requests
  const latestSgmOddsRequest = useRef();

  const { clearOutcomes, selectedOutcomes, removeOutcome } = useSgm();

  useEffect(() => {
    // console.log('Clear SGM Outcomes Selection');
    clearOutcomes();
  }, [event?.id]);

  useEffect(() => {
    if (outcomeErr) {
      ToastManager.error(() => t('sgm_not_priced'));
    }
  }, [outcomeErr]);

  const fetchSgmOdds = useCallback(
    ({ selectedOutcomes }) => {
      setOutcome(null);
      if (selectedOutcomes.length > 1) {
        setIsFetchingOdds(true);

        // both local variable and "ref" are set to the same value.
        // when promise is settled (with data or error) local variable should be compared to "global" "ref"
        // and don't change state if they don't match (this happens only if later request reassigned the ref,
        // then state will be changed only in response to the latest (latest started) request regardless if response took more
        // or less time than the previous request)
        // eslint-disable-next-line no-multi-assign
        const currentRequest = (latestSgmOddsRequest.current = BetManager.getSgmOdds({
          outcomes: selectedOutcomes.map((outcome) => ({
            outcomeId: outcome.outcomeId,
          })),
          returnOutcomeData: true,
        })
          .then((data) => {
            if (currentRequest === latestSgmOddsRequest.current) {
              if (data.status === 'FAILURE') {
                setOutcomeErr(data);
                setOutcome(null);

                window.setTimeout(() => setOutcomeErr(null), 3000);

                // ToastManager.warning(() => <Label message='sgm_not_priced' />);
                // deselect the last selected outcome
                removeOutcome({
                  outcomeId: selectedOutcomes[selectedOutcomes.length - 1].outcomeId,
                });
              } else if (selectedOutcomes.length > 1) {
                setOutcome(data);
              }
              setIsFetchingOdds(false);
            }
          })
          .catch(() => {
            if (currentRequest === latestSgmOddsRequest.current) {
              setIsFetchingOdds(false);
            }
          }));

        /**
         * for local dev (resolve price instantly, no backend communication needed):
         */

        // setOutcome({
        //   outcomeId: 66915843,
        //   marketId: 49226737,
        //   marketTypeId: 230014,
        //   odds: 33.22,
        //   legs: selectedOutcomes.map((outcome) => ({
        //     outcomeId: outcome.outcomeId,
        //   })),
        // })
      }
    },
    [removeOutcome],
  );

  const fetchSgmOddsDebounced = useCallback(debounce(fetchSgmOdds, 800), [fetchSgmOdds]);

  useEffect(() => {
    fetchSgmOddsDebounced({ selectedOutcomes });
  }, [selectedOutcomes, event.id, fetchSgmOddsDebounced]);

  useEffect(() => {
    if (outcome) {
      const { outcomeId, marketId, marketTypeId, odds, legs } = outcome;

      // add new SGM outcome to event state (or update)
      Bus.send({
        event: Bus.events.sport.eventUpdate,
        data: {
          data: {
            events: {
              [event.id]: {
                id: event.id,
                marketTypes: {
                  [marketTypeId]: {
                    id: marketTypeId,
                    markets: {
                      [marketId]: {
                        id: marketId,
                        status: 'ACTIVE',
                        outcomes: {
                          [outcome.outcomeId]: {
                            id: outcomeId,
                            odds,
                            status: 'ACTIVE',
                            sgmLegs: legs.map((leg) => leg.outcomeId),
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      });
    }
  }, [outcome]);

  const addToBet = useCallback(
    (e) => {
      const { outcomeId, marketId, marketTypeId, odds } = outcome;

      if (layout.mobileDevice) {
        oddsAnimation(
          e.target.classList.contains('btn') ? e.target : e.target.parentNode,
          true,
          outcome?.odds ? formatOdds(odds, 3, 1) : '',
        );
      }

      BetManager.selectBet({
        betEventType: BET_EVENT_TYPE.SPORT_PREMATCH,
        event,
        eventId: event.id,
        marketTypeId,
        marketId,
        outcomeId,
        betGroups: [0],
        isNew: true,
        betSlipGroup: BETSLIP_GROUP.SAME_GAME_MULTI,
      });

      // clearOutcomes();
    },
    [outcome, event],
  ); // event gets changed above, therefore need to account this change into memoized callback

  const betNow = useCallback(() => {
    const { outcomeId, marketId, marketTypeId } = outcome;

    BetManager.selectBet({
      betEventType: BET_EVENT_TYPE.SPORT_PREMATCH,
      event,
      eventId: event.id,
      marketTypeId,
      marketId,
      outcomeId,
      betGroups: [0],
      isNew: true,
      betSlipGroup: BETSLIP_GROUP.SAME_GAME_MULTI,
      betNow: true,
    });

    Bus.send({
      event: Bus.events.layout.setSideBarRight,
      data: true,
    });
  }, [outcome, event]);

  return (
    selectedOutcomes?.length > 0 && (
      <div className='selectedOdds'>
        <div className='collapse__wrapper'>
          <div className='header' onClick={() => setIsExpanded(!isExpanded)}>
            <div className='icon'>
              <span className={`AIcon-angle-${isExpanded ? 'down' : 'up'}`} />
            </div>
            <div className='title'>
              {`${isExpanded ? t('SGM_Hide_Selections') : t('SGM_Show_Selections')}`}
            </div>
          </div>
          {isExpanded && (
            <div className='content'>
              {selectedOutcomes.map(({ outcomeId, marketId, marketTypeId }) => (
                <div key={outcomeId} className='row'>
                  <div className='index'>
                    {event?.marketTypes[marketTypeId]?.markets[marketId]?.outcomes[outcomeId].name}
                  </div>
                  <div className='name'>{event?.marketTypes[marketTypeId].name}</div>
                </div>
              ))}
            </div>
          )}
          <div className='action_section'>
            <div className='legs'>
              <div className='legs__count'>
                <span className='legs__text'>{t('Legs')}</span>
                {selectedOutcomes && <span className='ml-1'>{selectedOutcomes.length}</span>}
              </div>
              <div className='odds'>
                {isFetchingOdds ? (
                  <div className='odds__spinner'>
                    <Loader className='fetchingOutcome' />
                  </div>
                ) : outcome ? (
                  <div className='odds__content'>
                    <div className='odds__text'>{t('odds')}</div>
                    <div className='value value-odds'>
                      {outcome && <Odds value={outcome?.odds} decimal={3} minDecimal={2} />}
                    </div>
                  </div>
                ) : (
                  <span className='multi-add-selection'>{t('add_selection')}</span>
                )}
              </div>
            </div>

            <div className='add_to_bet'>
              <SelectedOddsButtons
                addToBet={(e) => addToBet(e)}
                betNow={betNow}
                addDisabled={!(outcome?.outcomeId || isFetchingOdds)}
                clearSelection={clearOutcomes}
                clearDisabled={!selectedOutcomes?.length}
              />
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default SectionOdds;
