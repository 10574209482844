import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  constants,
  useApplicationState,
  useBetslipState,
  useBetslipStateUpdate,
  useObservableOddsContext,
} from '@apollo/core';
import { BONUS_TYPE } from '@apollo/core/src/constants';
import useTranslate from '../../../shared/components/I18n/Interpreter';
import ModalPortal from '../../../shared/components/ModalPortal';
import BonusModal from '../../../shared/modals/Bonus/BonusModal';
import Label from '../../../shared/components/I18n/Label';

const BetBonusButtons = ({ requestId, handleBetAmountChange, bonuses = [] }) => {
  const t = useTranslate();
  const { authenticated } = useApplicationState();
  const [isBonusModalOpen, setIsBonusModalOpen] = useState(false);
  const [isAcceptOddsChangedMsg, setIsAcceptOddsChangedMsg] = useState(false);
  const { bonusAssignments } = useBetslipState();
  const betslipUpdate = useBetslipStateUpdate();
  const { hasUnacceptedOdds } = useObservableOddsContext();

  const currentBetAssignments = useMemo(
    () => (bonusAssignments || [])
      .filter((bonusAssignment) => bonusAssignment.requestId === requestId)
      .map((ba) => ({
        ...ba,
        bonusType: bonuses.find((b) => b.id === ba.bonusId)?.bonusType,
      })),
    [bonusAssignments, requestId, bonuses],
  );

  const isBonusAssignedToBet = useCallback(
    (bonusId) => currentBetAssignments?.some((betAssignment) => betAssignment.bonusId === bonusId),
    [currentBetAssignments],
  );

  const isBonusAssignedToOtherBet = useCallback(
    (bonusId) => bonusAssignments?.some((ba) => ba.bonusId === bonusId && ba.requestId !== requestId),
    [bonusAssignments, requestId],
  );

  const assignBonusToBet = useCallback(
    (bonusId) => {
      if (!isBonusAssignedToBet(bonusId)) {
        betslipUpdate({
          bonusAssignments: [
            ...(bonusAssignments.filter((ba) => ba.requestId !== requestId) // remove other bonuses for this outcome
              || []),
            { bonusId, requestId },
          ],
        });
      }
    },
    [bonusAssignments, betslipUpdate, isBonusAssignedToBet, requestId],
  );

  const removeBonusAssignment = useCallback(
    (bonusId) => {
      betslipUpdate({
        bonusAssignments: (bonusAssignments || []).filter((it) => it.bonusId !== bonusId),
      });
    },
    [bonusAssignments, betslipUpdate],
  );

  const isBonusAssigned = useCallback(
    (bonusId) => (bonusAssignments || []).some((it) => it.bonusId === bonusId),
    [bonusAssignments],
  );

  const freeBetBonuses = useMemo(
    () => (bonuses || []).filter(({ bonusType }) => bonusType === BONUS_TYPE.FREEBET),
    [bonuses],
  );

  const onUseBonusBet = useCallback(
    // use freebet
    ({ id: bonusId }) => {
      assignBonusToBet(bonusId);
      setIsBonusModalOpen(false);
    },
    [assignBonusToBet],
  );

  const assignedBonus = useMemo(
    () => bonuses.find(({ id: bonusId }) => currentBetAssignments.some(
      ({ bonusId: betAssignmentBonusId }) => betAssignmentBonusId === bonusId,
    )),
    [bonuses, currentBetAssignments],
  );

  const handleBonusClick = ({ id: bonusId, bonusType }) => {
    if (bonusType === BONUS_TYPE.FREEBET) {
      const assignedBonus = currentBetAssignments.find((ba) => ba.bonusType === bonusType);

      if (assignedBonus) {
        removeBonusAssignment(assignedBonus.bonusId);
        handleBetAmountChange(0, true);
      } else {
        setIsBonusModalOpen(true);
      }
    } else if (isBonusAssignedToBet(bonusId)) {
      removeBonusAssignment(bonusId);
    } else if (!isBonusAssigned(bonusId)) {
      if (hasUnacceptedOdds && bonusType === BONUS_TYPE.ODDS_BOOST) {
        // can't assign odds boost if odds changed
        setIsAcceptOddsChangedMsg(true);
      } else {
        setIsAcceptOddsChangedMsg(false);
        assignBonusToBet(bonusId);
      }
    }
  };

  useEffect(() => {
    if (!hasUnacceptedOdds) {
      setIsAcceptOddsChangedMsg(false);
    }
  }, [hasUnacceptedOdds]);

  // todo: when we receive an "Odds Changed" error response, a chain of useEffect create a stackoverflow.
  // There is something wrong with the BetSlip state management. Perhaps too much editing happens inside useEffect.
  // useEffect(() => {
  //   if (validationStatus !== 'VALIDATION_SUCCEEDED') {
  //     return;
  //   }
  //   const mandatoryBonuses = bonuses.filter((b) => b.props.mandatory);
  //   if (!isEmpty(mandatoryBonuses)) {
  //     const bonusId = mandatoryBonuses[0].id;
  //     assignBonusToBet(bonusId);
  //   }
  // }, [assignBonusToBet, bonuses, validationStatus]);

  if (!requestId || !authenticated) {
    return null;
  }

  return (
    <>
      <div className='b-row controls__block btn-bonus__block'>
        {[
          constants.BONUS_TYPE.FREEBET,
          constants.BONUS_TYPE.BET_RETURN,
          constants.BONUS_TYPE.ODDS_BOOST,
        ]
          .map((bonusType) => bonuses?.find(
            (bonus) => bonus.bonusType === bonusType && !isBonusAssignedToOtherBet(bonus.id),
          ))
          .filter(Boolean)
          .map((bonus) => (
            <div className='btn-bonus__wrapper' key={bonus.id}>
              <button
                type='button'
                className={`btn btn-sm btn-bonus ${
                  currentBetAssignments.find((ba) => ba.bonusType === bonus.bonusType)
                    ? 'active'
                    : ''
                }
                ${
                  bonus.bonusType === constants.BONUS_TYPE.ODDS_BOOST
                  || bonus.bonusType === constants.BONUS_TYPE.PROMO_ODDS
                    ? 'animated'
                    : ''
                }`}
                onClick={() => handleBonusClick(bonus)}
              >
                <i className={`icons icon--${bonus.bonusType.toLowerCase()}-black`} />
                {t(bonus.bonusType)}
              </button>
            </div>
          ))}
        {isBonusModalOpen ? (
          <ModalPortal forceInteraction onClose={() => setIsBonusModalOpen(false)}>
            <BonusModal
              onClose={() => setIsBonusModalOpen(false)}
              betBonuses={freeBetBonuses}
              onUse={onUseBonusBet}
              bonusTypes={[constants.BONUS_TYPE.FREEBET]}
            />
          </ModalPortal>
        ) : null}
      </div>
      {[
        constants.BONUS_TYPE.ODDS_BOOST,
        constants.BONUS_TYPE.PROMO_ODDS,
        constants.BONUS_TYPE.BET_RETURN,
      ].includes(assignedBonus?.bonusType) ? (
        <div className='b-row controls__block bonus__description'>{assignedBonus?.description}</div>
        ) : null}

      {isAcceptOddsChangedMsg ? (
        <div className='b-row controls__block bonus__warn'>
          <i className='AkrIcon-warning' />
          <Label message='accept changed odds first' />
        </div>
      ) : null}
    </>
  );
};

export default BetBonusButtons;

BetBonusButtons.propTypes = {
  requestId: PropTypes.number,
  handleBetAmountChange: PropTypes.func.isRequired,
  bonuses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      bonusType: PropTypes.string.isRequired,
    }),
  ),
};

BetBonusButtons.defaultProps = {
  requestId: null,
  bonuses: [],
};
