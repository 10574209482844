import React, { useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty, map } from 'lodash';
import { RacingSelector } from '@apollo/core/src/state/racing/racing';
import RaceLink from '../../shared/components/Racing/RaceLink';
import RaceCountdown from '../../shared/components/Racing/RaceCountdown/RaceCountdown';
import { RacingIcon } from '../../shared/components/Racing/RacingIcon';
import Label from '../../shared/components/I18n/Label';
import useNextRaces from './useNextRaces';
import { RACE_COUNTRIES } from '../../core/constants';
import BetReturnBonusIcon from '../../shared/components/Bonus/BetReturnBonusIcon';
import PromoOddsBonusIcon from '../../shared/components/Bonus/PromoOddsBonusIcon';
import Loader from '../../shared/components/Loader';
import useConditionalLoading from '../../shared/components/Loader/useConditionalLoading';

const NextToJumpPage = ({ raceTypes = [], selectedCountries }) => {
  const [nextToJump, nextRacesUpdate] = useNextRaces({
    raceTypes,
  });

  const races = useMemo(
    () => nextToJump.filter(
      (race) => (selectedCountries[RACE_COUNTRIES.local] && ['AUS', 'NZL'].includes(race.countryId))
          || (selectedCountries[RACE_COUNTRIES.international]
            && !['AUS', 'NZL'].includes(race.countryId)),
    ),
    [nextToJump, selectedCountries],
  );

  const loading = RacingSelector.selectIsLoading();
  const [isLoading, setIsLoading] = useConditionalLoading({
    loading,
  });

  if (isEmpty(races)) {
    if (isLoading) {
      return <Loader />;
    }
    return (
      <div className='no_venues'>
        <Label message='No Venues' />
      </div>
    );
  }

  return (
    <div className='next-jump-page'>
      <div className='venue_race_table venue_race__mobile'>
        <div className='venue_race_table_data '>
          {map(races, (r) => (
            <RaceLink
              key={r.raceId}
              className='venue_race_table_row'
              raceDate={r.meeting.date}
              raceType={r.raceType.typeName}
              raceCountry={r.venue.country}
              raceVenue={r.venue.venueName}
              raceNumber={r.number}
            >
              <div className='venue_race_table_row_item__data first'>
                <div className='venue_race_table_row_item__data'>
                  <div className='venue_race_table_row_item__data__link'>
                    <div className='event-icon'>
                      <RacingIcon id={r?.raceType?.id} name={r.raceType?.typeName} />
                    </div>
                    <div>
                      <span className='racing-icon-name'>
                        <span className='race-number'>
                          R
                          {r.number}
                        </span>
                        <span className='name'>{r.venue.venueName}</span>
                      </span>
                      <div className='country'>{r.venue.countryName || r.venue.country}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='race-summary__timer__wrapper'>
                <BetReturnBonusIcon race={r} />
                <PromoOddsBonusIcon race={r} />
                <div className='race-summary__timer'>
                  <RaceCountdown
                    className='next-jump-box__item-countdown'
                    key={`${r?.raceId}-${r?.status}-${r?.result}`}
                    date={new Date(r.startTime)}
                    completeText={r.result || r.status}
                    onComplete={nextRacesUpdate}
                  />
                </div>
              </div>
              {/* <div className='next-jump-box__item-title'>
                {layout.mobileDevice === false ? (
                  <div className=''>
                    <RacingIcon id={r?.raceType?.id} name={r.raceType?.typeName} />
                  </div>
                ) : null}
                <span className='race-number'>
                  {layout.mobileDevice === true ? 'Race ' : 'R'}
                  {r.number}
                </span>
                <span className='race-name'>{r.venue.venueName}</span>
              </div>
              <div className='next-jump-box__item-countdown--wrapper'>
                {layout.mobileDevice === true ? (
                  <div className=''>
                    <RacingIcon id={r?.raceType?.id} name={r.raceType?.typeName} />
                  </div>
                ) : null}
                <RaceCountdown
                  className='next-jump-box__item-countdown'
                  key={`${r?.raceId}-${r?.status}-${r?.result}`}
                  date={new Date(r.startTime)}
                  completeText={r.result || r.status}
                  onComplete={onNextJumpStart}
                />
              </div> */}
            </RaceLink>
          ))}
        </div>
      </div>
    </div>
  );
};
export default NextToJumpPage;
