import React, { useEffect, useRef } from 'react';
import { SportsActions, useSportState } from '@apollo/core/src/state/sport/sport';
import Label from '../../shared/components/I18n/Label';
import useTranslate from '../../shared/components/I18n/Interpreter';
import SportLoader from './SportLoader';

const SvgEmptyIcon = (
  <svg
    width='216px'
    height='123px'
    viewBox='0 0 216 123'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='no-events-my-bets-empty' transform='translate(-692.000000, -430.000000)'>
        <g id='Group-11-Copy-2' transform='translate(264.000000, 261.000000)'>
          <g id='empty-event' transform='translate(428.000000, 171.000000)'>
            <path
              d='M119,19 C122.865993,19 126,22.1340068 126,26 C126,29.8659932 122.865993,33 119,33 L183,33 C186.865993,33 190,36.1340068 190,40 C190,43.8659932 186.865993,47 183,47 L205,47 C208.865993,47 212,50.1340068 212,54 C212,57.8659932 208.865993,61 205,61 L186,61 C182.134007,61 179,64.1340068 179,68 C179,71.8659932 182.134007,75 186,75 L192,75 C195.865993,75 199,78.1340068 199,82 C199,85.8659932 195.865993,89 192,89 L46,89 C42.1340068,89 39,85.8659932 39,82 C39,78.1340068 42.1340068,75 46,75 L7,75 C3.13400675,75 0,71.8659932 0,68 C0,64.1340068 3.13400675,61 7,61 L47,61 C50.8659932,61 54,57.8659932 54,54 C54,50.1340068 50.8659932,47 47,47 L22,47 C18.1340068,47 15,43.8659932 15,40 C15,36.1340068 18.1340068,33 22,33 L62,33 C58.1340068,33 55,29.8659932 55,26 C55,22.1340068 58.1340068,19 62,19 L119,19 Z M209,75 C212.865993,75 216,78.1340068 216,82 C216,85.8659932 212.865993,89 209,89 C205.134007,89 202,85.8659932 202,82 C202,78.1340068 205.134007,75 209,75 Z'
              id='BG'
              fill='var(--dt-light-1)'
            />
            <g id='Group-24' transform='translate(60.000000, 8.000000)'>
              <g id='Group-21'>
                <path
                  d='M96,48 C96,59.43 92.01,69.92 85.34,78.16 C80.07,84.69 73.11,89.8 65.14,92.85 C59.82,94.89 54.04,96 48,96 C41.96,96 36.18,94.89 30.86,92.85 C22.89,89.8 15.93,84.69 10.66,78.16 C3.99,69.92 0,59.43 0,48 C0,47.18 0.02,46.36 0.06,45.55 C0.5,36.81 3.27,28.69 7.77,21.81 C14.14,12.05 23.96,4.74 35.51,1.64 C39.49,0.57 43.68,0 48,0 C52.32,0 56.51,0.57 60.49,1.64 L60.5,1.64 C72.04,4.74 81.86,12.05 88.23,21.8 L88.23,21.81 C92.73,28.69 95.5,36.81 95.94,45.55 C95.98,46.36 96,47.18 96,48'
                  id='Fill-1'
                  fill='var(--dt-base-color)'
                />
                <path
                  d='M96,48 C96,59.43 92.01,69.92 85.34,78.16 C80.07,84.69 73.11,89.8 65.14,92.85 C59.82,94.89 54.04,96 48,96 C41.96,96 36.18,94.89 30.86,92.85 C22.89,89.8 15.93,84.69 10.66,78.16 C3.99,69.92 0,59.43 0,48 C0,47.18 0.02,46.36 0.06,45.55 C0.5,36.81 3.27,28.69 7.77,21.81 C14.14,12.05 23.96,4.74 35.51,1.64 C39.49,0.57 43.68,0 48,0 C52.32,0 56.51,0.57 60.49,1.64 L60.5,1.64 C72.04,4.74 81.86,12.05 88.23,21.8 L88.23,21.81 C92.73,28.69 95.5,36.81 95.94,45.55 C95.98,46.36 96,47.18 96,48 Z'
                  id='Stroke-3'
                  stroke='var(--dt-light-3)'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                />
                <g id='Group-8' transform='translate(29.000000, 30.000000)'>
                  <path
                    d='M17.0181,35.5874 L25.3721,35.5874 C28.7441,35.5874 31.7311,33.4174 32.7731,30.2114 L36.7111,18.0894 C37.7531,14.8834 36.6121,11.3714 33.8851,9.3894 L23.5741,1.8984 C20.8471,-0.0826 17.1531,-0.0826 14.4261,1.8984 L4.1151,9.3894 C1.3881,11.3714 0.2471,14.8834 1.2891,18.0894 L3.9021,26.1334 L5.2271,30.2114'
                    id='Fill-5'
                    fill='var(--dt-base-color)'
                  />
                  <path
                    d='M17.0181,35.5874 L25.3721,35.5874 C28.7441,35.5874 31.7311,33.4174 32.7731,30.2114 L36.7111,18.0894 C37.7531,14.8834 36.6121,11.3714 33.8851,9.3894 L23.5741,1.8984 C20.8471,-0.0826 17.1531,-0.0826 14.4261,1.8984 L4.1151,9.3894 C1.3881,11.3714 0.2471,14.8834 1.2891,18.0894 L3.9021,26.1334 L5.2271,30.2114'
                    id='Stroke-7'
                    stroke='var(--dt-light-3)'
                    strokeWidth='2.5'
                    strokeLinecap='round'
                  />
                </g>
                <path
                  d='M30.8599,92.8501 L30.2899,91.0901 C29.2499,87.8801 30.3899,84.3701 33.1199,82.3901 L43.4299,74.9001 C46.1499,72.9201 49.8499,72.9201 52.5699,74.9001 L62.8799,82.3901 C65.6099,84.3701 66.7499,87.8801 65.7099,91.0901 L65.1399,92.8501'
                  id='Stroke-9'
                  stroke='var(--dt-light-3)'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                />
                <path
                  d='M85.3398,78.1602 L83.5098,78.1602 C80.1398,78.1602 77.1498,75.9902 76.1098,72.7802 L72.1698,60.6602 C71.1298,57.4602 72.2698,53.9402 74.9998,51.9602 L85.3098,44.4702 C88.0298,42.4902 91.7298,42.4902 94.4498,44.4702 L95.9398,45.5502'
                  id='Stroke-11'
                  stroke='var(--dt-light-3)'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                />
                <path
                  d='M88.23,21.7998 L88.23,21.8098 L87.66,23.5498 C86.61,26.7598 83.63,28.9298 80.26,28.9298 L67.51,28.9298 C64.14,28.9298 61.15,26.7598 60.11,23.5498 L56.17,11.4298 C55.13,8.2198 56.27,4.7098 59,2.7298 L60.49,1.6398 L60.5,1.6398'
                  id='Stroke-13'
                  stroke='var(--dt-light-3)'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                />
                <path
                  d='M37.9727,17.144 L35.8897,23.55 C34.8497,26.76 31.8597,28.93 28.4907,28.93 L20.3787,28.93'
                  id='Stroke-15'
                  stroke='var(--dt-light-3)'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                />
                <path
                  d='M0.0601,45.5498 L1.5501,44.4698 C4.2701,42.4898 7.9701,42.4898 10.6901,44.4698 L21.0001,51.9598 C23.7301,53.9398 24.8701,57.4598 23.8301,60.6598 L19.8901,72.7798 C18.8501,75.9898 15.8601,78.1598 12.4901,78.1598 L10.6601,78.1598'
                  id='Stroke-19'
                  stroke='var(--dt-light-3)'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                />
              </g>
              <path
                d='M36.1826,9.7012 C34.1436,10.4662 32.1526,11.3952 30.2276,12.4902 C27.2386,14.1902 24.4116,16.2892 21.8246,18.7872 C20.7636,19.8122 19.7686,20.8792 18.8396,21.9832 M15.9646,25.8262 C15.2256,26.9432 14.5466,28.0872 13.9256,29.2552 C13.4666,30.1182 13.0396,30.9932 12.6456,31.8802'
                id='Stroke-22'
                stroke='var(--dt-light-3)'
                strokeWidth='2.5'
                strokeLinecap='round'
              />
            </g>
            <g
              id='bling'
              strokeLinecap='round'
              strokeLinejoin='round'
              transform='translate(149.000000, 0.000000)'
              stroke='var(--dt-light-5)'
              strokeWidth='2.5'
            >
              <line x1='10.5' y1='0' x2='10.5' y2='6' id='Line' />
              <line x1='10.5' y1='14' x2='10.5' y2='20' id='Line' />
              <line
                x1='3.5'
                y1='7'
                x2='3.5'
                y2='13'
                id='Line'
                transform='translate(3.500000, 10.000000) rotate(-90.000000) translate(-3.500000, -10.000000) '
              />
              <line
                x1='17.5'
                y1='7'
                x2='17.5'
                y2='13'
                id='Line'
                transform='translate(17.500000, 10.000000) rotate(-90.000000) translate(-17.500000, -10.000000) '
              />
            </g>
            <g
              id='bling'
              strokeLinecap='round'
              strokeLinejoin='round'
              transform='translate(68.000000, 103.000000)'
              stroke='var(--dt-light-5)'
              strokeWidth='2.5'
            >
              <line x1='8.5' y1='0' x2='8.5' y2='4' id='Line' />
              <line x1='8.5' y1='12' x2='8.5' y2='16' id='Line' />
              <line
                x1='2.5'
                y1='6'
                x2='2.5'
                y2='10'
                id='Line'
                transform='translate(2.500000, 8.000000) rotate(-90.000000) translate(-2.500000, -8.000000) '
              />
              <line
                x1='14.5'
                y1='6'
                x2='14.5'
                y2='10'
                id='Line'
                transform='translate(14.500000, 8.000000) rotate(-90.000000) translate(-14.500000, -8.000000) '
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const SportsbookLoader = (props) => {
  const t = useTranslate();
  const timerRef = useRef(null);
  const [sportsState, sportsDispatcher] = useSportState();
  const { loading, initialized, error } = sportsState;

  useEffect(() => {
    if (error) {
      window.clearTimeout(timerRef.current);
      timerRef.current = window.setTimeout(() => {
        sportsDispatcher({
          type: SportsActions.RESET_ERROR,
          payload: {},
        });
      }, 5000);
    }
    return () => {
      window.clearTimeout(timerRef.current);
    };
  }, [error, sportsDispatcher]);

  if (!initialized && loading) {
    return <SportLoader />;
  }

  if (error) {
    console.error(error);
    return (
      <div className='empty-state sportsbook-error'>
        <img
          width={200}
          src='./static/assets/images/no-events.png'
          alt={t('sportsbook_no_events')}
        />
        <h2>
          <Label className='empty-games-label' message='sportsbook_no_events' />
        </h2>
        <h5>{error?.error || error?.message}</h5>
      </div>
    );
  }

  return props.children;
};
export default SportsbookLoader;
