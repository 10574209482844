import React, { createContext, useContext, useCallback, useMemo, useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Theme from 'themeSource/custom';
import Label from '../../shared/components/I18n/Label';
import ConfettiAnimation from './ConfettiAnimation';

const GiftBoxAnimationContext = createContext();

const GiftBoxAnimationProvider = ({ children }) => {
  const isJumpingGiftBoxEnabled = Theme?.animations?.bonus;

  const [isVisible, setIsVisible] = useState(false);
  const [prefix, setPrefix] = useState('');
  const [icon, setIcon] = useState('');
  const [isTabVisible, setIsTabVisible] = useState(!document.hidden);
  const [queuedAnimation, setQueuedAnimation] = useState(null);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabVisible(!document.hidden);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (isTabVisible && queuedAnimation) {
      const { duration, config } = queuedAnimation;
      setQueuedAnimation(null);

      setIsVisible(true);
      setPrefix(config?.prefix ? config.prefix.toLowerCase() : '');
      setIcon(config?.icon ? config.icon.toLowerCase() : '');

      setTimeout(() => {
        setIsVisible(false);
      }, duration);
    }
  }, [isTabVisible, queuedAnimation]);

  const calculatePosition = () => {
    const targetElement = document.querySelector('.account__current .user_profile');
    if (targetElement) {
      const rect = targetElement.getBoundingClientRect();
      return {
        opacity: 0,
        scale: 0,
        x: rect.left - window.innerWidth / 2 + 25,
        y: rect.top - window.innerHeight / 2 + 25,
      };
    }
  };

  const runGiftBoxAnimation = useCallback((duration, config) => {
    if (!isJumpingGiftBoxEnabled) return;

    const currentTabVisible = !document.hidden;

    if (!currentTabVisible) {
      setQueuedAnimation({ duration, config });
      return;
    }

    setIsVisible(true);
    setPrefix(config?.prefix ? config.prefix.toLowerCase() : '');
    setIcon(config?.icon ? config.icon.toLowerCase() : '');

    setTimeout(() => {
      setIsVisible(false);
      setIcon('');
    }, duration);
  }, [isJumpingGiftBoxEnabled]);

  const contextValue = useMemo(() => ({ runGiftBoxAnimation }), [runGiftBoxAnimation]);

  return (
    <GiftBoxAnimationContext.Provider value={contextValue}>
      {children}

      <AnimatePresence>
        {isJumpingGiftBoxEnabled && isVisible && (
          <motion.div
            key='jumping-gift-box'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1, delay: 1 }}
            className='jumping-gift-box-wrapper'
          >
            {Theme.animations?.bonusConfetti && <ConfettiAnimation />}

            <div className='gift-box'>
              <motion.div
                className='box jump'
                initial={{ scale: 1, rotate: '0deg' }}
                animate={{ scale: 1 }}
                exit={calculatePosition()}
                transition={{ duration: 1 }}
              >
                <div className='ribbon' />
                <div className='lid' />
                <i className={`icons bonus-icon ${icon || prefix}`} />
                <Label message={`${prefix}_giftbox_anim`} className='label' />
              </motion.div>
            </div>

            <div className='shadow' />
          </motion.div>
        )}
      </AnimatePresence>
    </GiftBoxAnimationContext.Provider>
  );
};

const useGiftBoxAnimation = () => {
  const context = useContext(GiftBoxAnimationContext);

  if (!context) {
    throw new Error('useJumpingGiftBoxAnimation must be used within a JumpingGiftBoxAnimationProvider');
  }

  return context;
};

export { GiftBoxAnimationProvider, useGiftBoxAnimation };
