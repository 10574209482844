import React, { useCallback } from 'react';
import Particles from 'react-tsparticles';
import { loadConfettiPreset } from 'tsparticles-preset-confetti';
import { loadImageShape } from 'tsparticles-shape-image';

const PARTICLES_CONFIG = {
  fullScreen: {
    zIndex: 999,
  },
  background: {
    color: {
      value: '#000000',
    },
    opacity: 0.6,
  },
  emitters: [
    {
      life: {
        count: 5,
      },
      position: {
        x: 0,
        y: 40,
      },
      rate: {
        delay: 0.1,
        quantity: 10,
      },
      particles: {
        move: {
          direction: 'top-right',
          speed: { min: 50, max: 100 },
        },
        size: {
          value: { min: 3, max: 6 },
        },
        number: {
          value: 0,
        },
      },
    },
    {
      life: {
        count: 5,
      },
      position: {
        x: 100,
        y: 40,
      },
      rate: {
        delay: 0.1,
        quantity: 10,
      },
      particles: {
        move: {
          direction: 'top-left',
          speed: { min: 50, max: 100 },
        },
        size: {
          value: { min: 3, max: 6 },
        },
        number: {
          value: 0,
        },
      },
    },
  ],
  preset: 'confetti',
};

const ConfettiAnimation = () => {
  const particlesInit = useCallback(async (engine) => {
    await loadConfettiPreset(engine);

    await loadImageShape(engine);
  }, []);

  return (
    <Particles
      id='tsparticles'
      init={particlesInit}
      options={PARTICLES_CONFIG}
    />
  );
};

export default ConfettiAnimation;
