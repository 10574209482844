import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BetManager, Bus, RacingManager, useApplicationState } from '@apollo/core';
import { isEmpty, map } from 'lodash';
import { RacingActions, useRacingState } from '@apollo/core/src/state/racing/racing';
import { BET_EVENT_TYPE } from '@apollo/core/src/constants';
import Theme from 'themeSource/custom';
import Label from '../../../../shared/components/I18n/Label';
import useTranslate from '../../../../shared/components/I18n/Interpreter';
import { RACE_STATUS } from '../../../../core/constants';
import Odds from '../../../../shared/components/Odds/Odds';
import Loader from '../../../../shared/components/Loader/Loader';
import DutchOdds from './Odds';
import {
  formatOdds,
  getFavOddRunner,
  getSortedRunners,
  oddsAnimation,
} from '../../../../core/utils';
import RaceFlucs from '../../raceFlucs/RaceFlucs';
import RaceRunnerRow from '../RaceRunnerRow';
import RaceFlucsHeader from '../../raceFlucs/RaceFlucsHeader';
import SelectedOddsButtons from '../../../../shared/components/SelectedOdds/SelectedOddsButtons';

const CombinedMarketPage = ({ runners, marketData, raceStatic, displaySettings = {} }) => {
  const t = useTranslate();
  const { layout } = useApplicationState();
  const [isExpanded, setIsExpanded] = useState(!!Theme.EXPANDED_SELECTION);
  const [outcome, setOutcome] = useState(null);
  const [isFetchingOutcome, setIsFetchingOutcome] = useState(false);
  const [racingState, racingDispatcher] = useRacingState();
  const { activeRace, outcomesByRunnerId, combinedSelections = [], marketsByType } = racingState;

  const { isFlucs, isSpeedMap, isForm } = displaySettings;

  const clearSelection = useCallback(() => {
    racingDispatcher({
      type: RacingActions.RACING_STATE_UPDATE,
      payload: {
        combinedSelections: [],
      },
    });
    setOutcome();
  }, [racingDispatcher]);

  const addToBet = useCallback(
    (e) => {
      if (layout.mobileDevice) {
        oddsAnimation(
          e.target.classList.contains('btn') ? e.target : e.target.parentNode,
          true,
          outcome?.odds ? formatOdds(outcome?.odds, 3, 1) : '',
        );
      }
      BetManager.selectBet({
        betEventType: BET_EVENT_TYPE.RACING,
        event: activeRace,
        eventId: activeRace.raceId,
        marketTypeId: 'COMBINED',
        marketId: marketsByType?.COMBINED?.marketId,
        outcomeId: outcome.outcomeId,
        // todo
        // Only a single bet of the same group can exist in the betslip
        // We need to identify if betGroups are required for Racing
        betGroups: [0],
        isNew: true,
      });

      clearSelection();
    },
    [activeRace, outcome, marketsByType],
  );

  const betNow = useCallback(() => {
    BetManager.selectBet({
      betEventType: BET_EVENT_TYPE.RACING,
      event: activeRace,
      eventId: activeRace.raceId,
      marketTypeId: 'COMBINED',
      marketId: marketsByType?.COMBINED?.marketId,
      outcomeId: outcome.outcomeId,
      // todo
      // Only a single bet of the same group can exist in the betslip
      // We need to identify if betGroups are required for Racing
      betGroups: [0],
      isNew: true,
      betNow: true,
    });

    Bus.send({
      event: Bus.events.layout.setSideBarRight,
      data: true,
    });
  }, [activeRace, outcome, marketsByType]);

  useEffect(() => {
    if (combinedSelections.length > 1) {
      setIsFetchingOutcome(true);
      RacingManager.getCombinedOutcome({
        raceId: activeRace.raceId,
        marketId: marketsByType?.COMBINED?.marketId,
        numbers: combinedSelections,
      })
        .then(({ outcome }) => {
          setOutcome(outcome);
          setIsFetchingOutcome(false);
        })
        .catch((err) => {
          setIsFetchingOutcome(false);
        });
    }
  }, []);

  const runnersByNum = runners.reduce((acc, r) => ({ ...acc, [r.number]: r }), {});

  const favOddRunner = getFavOddRunner(runners, outcomesByRunnerId);

  const [sortByField, setSortByField] = useState({
    field: 'runner',
    asc: true,
  });

  const onSort = (field) => {
    setSortByField({
      field,
      asc: sortByField.field === field ? !sortByField.asc : true,
    });
  };

  const sortedRunners = useMemo(() => {
    const sortedRunners = getSortedRunners(
      runners,
      outcomesByRunnerId,
      sortByField.field,
      sortByField.asc,
    );

    return sortedRunners.sort((a, b) => a.scratched - b.scratched);
  }, [runners, sortByField, outcomesByRunnerId]);

  return (
    <div className='dutch_book_wrapper'>
      <div className='tournament tournament--classic racelist'>
        <div className='tournament__header'>
          <div className='event event--classic'>
            <div className='event__main-period'>
              <div className='event__info'>
                <div className='event-participants'>
                  <div
                    className={`event-participants__participant sorting ${
                      sortByField.field === 'runner' && (sortByField.asc ? 'asc' : 'desc')
                    }`}
                    onClick={() => onSort('runner')}
                  >
                    <Label message='Runner' />
                  </div>
                </div>
              </div>
              {!_.isEmpty(activeRace?.fwinOutcomesWithOddsHistories) ? <RaceFlucsHeader /> : null}
              <div className='event__market-row'>
                <div className='event__market-row-market'>
                  <div className='srm__row_wrapper'>
                    <div className='srm__row_values'>
                      <span
                        className={`sorting ${
                          sortByField.field === 'F_WIN' && (sortByField.asc ? 'asc' : 'desc')
                        }`}
                        onClick={() => onSort('F_WIN')}
                      >
                        {t('Win')}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='event-group event-group--races'>
          {marketData
            && !isEmpty(sortedRunners)
            && map(sortedRunners, (r) => (
              <RaceRunnerRow
                key={r.runnerId}
                runner={r}
                runnerStatic={raceStatic?.runners?.find((runner) => runner.runnerId === r.runnerId)}
                activeRace={activeRace}
                isFlucs={isFlucs}
                isForm={isForm}
                isSpeedMap={isSpeedMap}
              >
                <RaceFlucs activeRace={activeRace} runner={r} />
                <div className='event__market-row'>
                  <div className='event__market-row-market'>
                    <div className='srm__row_wrapper'>
                      <div className='srm__row_values'>
                        <div key={`${r.runnerId}`}>
                          {!r.scratched && outcomesByRunnerId?.[r.runnerId]?.F_WIN != null ? (
                            <DutchOdds
                              setOutcome={setOutcome}
                              race={activeRace}
                              runner={r}
                              odds={outcomesByRunnerId?.[r.runnerId]?.F_WIN?.odds}
                              selected={
                                combinedSelections.findIndex((sel) => sel === r.number) > -1
                              }
                              setIsFetchingOutcome={setIsFetchingOutcome}
                            />
                          ) : null}
                          {activeRace.status === RACE_STATUS.OPEN && favOddRunner === r.runnerId ? (
                            <Label message='fav' className='favorite' />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </RaceRunnerRow>
            ))}
        </div>
        {combinedSelections?.length ? (
          <div className='selectedOdds'>
            <div className='collapse__wrapper'>
              <div className='header' onClick={() => setIsExpanded(!isExpanded)}>
                <div className='icon'>
                  <span className={`AIcon-angle-${isExpanded ? 'down' : 'up'}`} />
                </div>
                <div className='title'>
                  {isExpanded ? (
                    <Label message='Hide Selections' />
                  ) : (
                    <Label message='Show Selections' />
                  )}
                </div>
              </div>
              {isExpanded ? (
                <div className='content'>
                  {combinedSelections.map((num, index) => (
                    <div key={index} className='row'>
                      <div className='index'>
                        {index + 1}
                        .
                      </div>
                      <div className='name'>
                        {runnersByNum?.[num]?.name}
                        {' '}
                        (
                        {num}
                        )
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
              <div className='action_section'>
                <div className='legs'>
                  <span>
                    <span className='legs__text'>{t('Legs')}</span>
                    {combinedSelections && (
                      <span className='ml-1 legs__combos'>{combinedSelections.length}</span>
                    )}
                  </span>
                  <div className='odds'>
                    {!outcome && !isFetchingOutcome ? (
                      <span className='multi-add-selection'>{t('add_selection')}</span>
                    ) : null}
                    {outcome && !isFetchingOutcome ? (
                      <div className='odds__content'>
                        <div className='odds__text'>{t('odds')}</div>
                        <span className='value value-odds'>
                          {outcome && <Odds value={outcome?.odds} />}
                        </span>
                      </div>
                    ) : null}
                    {isFetchingOutcome && <Loader className='fetchingOutcome' />}
                  </div>
                </div>
                <div className='add_to_bet'>
                  <SelectedOddsButtons
                    addToBet={(e) => addToBet(e)}
                    betNow={betNow}
                    addDisabled={!outcome}
                    clearSelection={clearSelection}
                    clearDisabled={!combinedSelections?.length}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CombinedMarketPage;
