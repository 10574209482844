import React, { useMemo } from 'react';
import { hasMarketTypes } from '@apollo/core/src/utils/status';
import { isHidden } from '@apollo/core';
import { isArray } from 'lodash';
import { BonusSelector } from '../../../state/Bonus/Bonus';
import { RACE_STATUS } from '../../../core/constants';
import useTranslate from '../I18n/Interpreter';

const PromoOddsBonusIcon = ({ race, event }) => {
  const t = useTranslate();

  const isShowIcon = useMemo(() => {
    if (race) {
      return race.status === RACE_STATUS.OPEN && BonusSelector.hasActivePromoOdds(race?.raceId);
    }

    return (
      // any of correlated id has assigned promo odds bonus
      event
      && hasMarketTypes(event)
      && !isHidden(event)
      && isArray(event?.correlatedIds)
      && event?.correlatedIds.find((id) => BonusSelector.hasActivePromoOdds(id))
    );
  }, [race, event]);

  if (!isShowIcon) {
    return null;
  }

  return <i className='icons icons icon--promo-odds' title={t('promo odds')} />;
};

export default PromoOddsBonusIcon;
