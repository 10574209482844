import React from 'react';
import cx from 'classnames';
import Label from '../../../../shared/components/I18n/Label';

const SpeedMapItem = ({ runnerStatic, label }) => {
  const { staticInfo } = runnerStatic;
  return staticInfo ? (
    <div className={`speed-map__item speed-map__item--${staticInfo.earlySpeedBand?.toLowerCase()}`}>
      <div className='progress'>
        <div
          className={cx('progress-bar', {
            empty: !staticInfo?.earlySpeedRating,
            'progress-bar--fixed-label':
              staticInfo?.earlySpeedRating && staticInfo.earlySpeedRating <= 15,
          })}
          style={{
            width: `${staticInfo.earlySpeedRating ? `${staticInfo.earlySpeedRating}%` : ''}`,
          }}
        >
          {label ? (
            <div
              className={`progress-label dot-band  dot-band--${staticInfo.earlySpeedBand?.toLowerCase()}`}
            >
              {label}
            </div>
          ) : null}
        </div>
      </div>
      <div
        className={`speed-label speed-label--${staticInfo.earlySpeedBand?.toLowerCase()} dot-band dot-band--${staticInfo.earlySpeedBand?.toLowerCase()}`}
      >
        {staticInfo.earlySpeedBand || <Label message='earlySpeedNoData' />}
      </div>
    </div>
  ) : null;
};

export default SpeedMapItem;
