import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import { Bus } from '@apollo/core';
import { BonusSelector } from '../../../../state/Bonus/Bonus';
import Loader from '../../../../shared/components/Loader';
import { isDateFuture } from '../bonusesUtils';
import Label from '../../../../shared/components/I18n/Label';
import RaceCountdown from '../../../../shared/components/Racing/RaceCountdown/RaceCountdown';

const EmptyBundleBanners = React.memo(() => (
  <div className='no-bonuses-message no-bonuses-message--bundles'>
    <h3>
      <Label message='no_bundles_available' />
    </h3>
    <Label message='check_bundles_later' />
  </div>
));

const BundleBannerItem = React.memo(({ bundle }) => {
  const activationHandler = useCallback((bundle) => {
    Bus.emit(Bus.events.profile.bundleAssign, bundle);
  }, []);

  const classes = cx('bonus-banners__item bonus-banners__item--bundle');
  return (
    <div key={bundle.id} className={classes}>
      <span className='bonus-icon'>
        <i className='icon bonus-icon--bundle' />
      </span>
      <div className='bonus-name'>{bundle.localizedName}</div>
      <div className='bonus-description'>{bundle.localizedDescription}</div>
      <div className='bonus-timer'>
        {bundle.expirationTime ? (
          <>
            <Label message='expires in' />
            <RaceCountdown date={new Date(bundle.expirationTime)} />
          </>
        ) : null}
        <button
          className='btn btn-sm btn-primary-outline btn-bonus-split'
          type='button'
          onClick={() => activationHandler(bundle)}
        >
          <Label message='activate' />
        </button>
      </div>
    </div>
  );
});

const BundleBanners = () => {
  const loading = BonusSelector.selectIsLoading();

  const bundles = BonusSelector.selectAllBundles();
  const filteredBundles = useMemo(
    () => bundles?.filter((b) => isDateFuture(b.expirationTime)),
    [bundles],
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <div className='wrapper'>
      <div className='bonus-banners bonus-banners--bundles'>
        <div className='bonus-banners-container'>
          {filteredBundles.map((b) => (
            <BundleBannerItem key={b.id} bundle={b} />
          ))}
          {filteredBundles?.length <= 0 ? <EmptyBundleBanners /> : null}
        </div>
      </div>
    </div>
  );
};

export default BundleBanners;
